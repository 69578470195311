import React from "react";
import { graphql, Link } from "gatsby";
import { withPrefix } from "gatsby-link";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { BsArrowRight } from "react-icons/bs";
import { RiArrowRightUpLine } from "react-icons/ri";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import TestimonialsSlider from "../components/Testimonials";

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx;
    const { frontmatter } = post;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const siteURL = this.props.data.site.siteMetadata.siteUrl;

    const { pageContext } = this.props;
    const { previous } = pageContext;

    let preFooterPost = previous;
    if (!preFooterPost) {
      preFooterPost = this.props.data.allMdx.edges[0].node;
    }

    const shareImage = `${siteURL}${frontmatter.thumbnail.publicURL}`;

    let preCategoriesImage = null;
    if (frontmatter.pre_categories_image) {
      preCategoriesImage = getImage(frontmatter.pre_categories_image);
    }

    const isDanielleBeauty = post.fields.slug === "/danielle-beauty/";

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        HeaderExtension={
          <div
            className="header_extension hero_style brand"
            style={{
              backgroundImage: `url(${frontmatter.featuredImage.childImageSharp.fluid.src})`,
              backgroundColor: frontmatter.hero_bg_color_mobile,
            }}
          >
            <div
              className="cp-mobile-bg is-mobile-only"
              style={{
                backgroundImage: `url(${frontmatter.featuredImage_mobile.childImageSharp.fluid.src})`,
              }}
            ></div>

            <div className="cp-content">
              <div className="container">
                <div className="columns is-vcentered">
                  <style
                    dangerouslySetInnerHTML={{
                      __html: `
					div.header_extension.hero_style.brand div.cp-content {
						color: ${frontmatter.hero_text_color_desktop};
                    }

					@media screen and (max-width: 768px) {
						div.header_extension.hero_style.brand div.cp-content {
							color: ${frontmatter.hero_text_color_mobile};
						}	
					}
					`,
                    }}
                  ></style>

                  <div className="column is-5">
                    <h1>{frontmatter.title}</h1>

                    <p className="cp-subtitle">{frontmatter.subtitle}</p>

                    <p>{frontmatter.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      >
        <SEO
          title={frontmatter.title}
          image={shareImage}
          description={frontmatter.description}
        />

        <div className="brand-single">
          {frontmatter.benefits && (
            <div
              className="section-benefits"
              style={{
                backgroundColor: frontmatter.benefits_bg,
              }}
            >
              <div className="container">
                <div className="columns">
                  {frontmatter.benefits.map((benefit) => (
                    <div className="column is-3">
                      <img src={benefit.icon} alt={benefit.title} />

                      <h4
                        style={{
                          color: frontmatter.benefits_text_color,
                        }}
                      >
                        {benefit.title}
                      </h4>

                      <p
                        style={{
                          color: frontmatter.benefits_text_color,
                        }}
                      >
                        {benefit.text}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {frontmatter.pre_categories_title && (
            <div
              className="section-categories aligncenter"
              style={{ paddingBottom: 0 }}
            >
              <div className="container">
                <div className="columns post-single ui-grid">
                  <div className="column is-12">
                    <h3 style={{ marginBottom: "2em" }}>
                      {frontmatter.pre_categories_title}
                    </h3>

                    <GatsbyImage
                      image={preCategoriesImage}
                      alt={frontmatter.pre_categories_title}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {frontmatter.categories && (
            <div className="section-categories">
              <style
                dangerouslySetInnerHTML={{
                  __html: `
					div.brand-single div.section-categories .img-with-bg:before {
						background-color: ${frontmatter.categories_image_border_color};
                    }
					`,
                }}
              ></style>

              <div className="container">
                <h5
                  style={{
                    color: frontmatter.categories_text_color,
                  }}
                >
                  Product categories
                </h5>

                {frontmatter.categories.map((category, i) => {
                  const categoryImage = getImage(category.featuredImage);

                  return (
                    <div
                      className={`columns is-vcentered ${
                        i % 2 !== 0 ? "is-reverse" : ""
                      }`}
                      style={{
                        color: frontmatter.categories_text_color,
                      }}
                    >
                      <div className="column is-6">
                        <GatsbyImage
                          image={categoryImage}
                          alt={category.title}
                          className="img-with-bg"
                        />
                      </div>

                      <div className="column is-1"></div>

                      <div className="column is-5">
                        <h2>{category.title}</h2>

                        <p>{category.description}</p>

                        {category.bullets && (
                          <ul>
                            {category.bullets.map((bullet) => (
                              <li>
                                <BsArrowRight /> {bullet.text}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {frontmatter.salsify_url && frontmatter.salsify_url.trim().length && 
            <div className="section-link">
              <div className="aligncenter">
                <a
                  href={frontmatter.view_products_url}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="button"
                >
                  View products <RiArrowRightUpLine />
                </a>
              </div>
            </div>
          }

          

          {frontmatter.instagram_handle && (
            <div
              className="section-instagram"
              style={{
                backgroundColor: frontmatter.instagram_bg,
                color: frontmatter.instagram_text_color,
              }}
            >
              <div className="container">
                <p>Follow us on Instagram</p>

                <a
                  href={`https://instagram.com/${frontmatter.instagram_handle}`}
                  target="_blank"
                  style={{
                    color: frontmatter.instagram_text_color,
                  }}
                >
                  @{frontmatter.instagram_handle} <RiArrowRightUpLine />
                </a>
              </div>
            </div>
          )}
        </div>

        {frontmatter.testimonials && (
          <div className="page-content pages-index">
            <TestimonialsSlider
              backgroundColor={frontmatter.testimonials_bg}
              slides={frontmatter.testimonials}
              textColor={frontmatter.testimonials_text_color}
              title={`People love ${frontmatter.title}`}
              type="brand"
            />
          </div>
        )}
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        subtitle
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featuredImage_mobile {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        benefits {
          title
          text
          icon
        }
        categories {
          title
          description
          bullets {
            text
          }
          featuredImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }

        hero_text_color_desktop
        hero_text_color_mobile
        hero_bg_color_mobile
        benefits_bg
        benefits_text_color
        categories_text_color
        categories_image_border_color
        view_products_url
        instagram_handle
        instagram_bg
        instagram_text_color
        testimonials_bg
        testimonials_text_color
        testimonials {
          name
          text
          product
        }
        thumbnail {
          publicURL
        }
        pre_categories_title
        pre_categories_image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
      body
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { posttype: { eq: "brands" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            posttype
          }
        }
      }
    }
  }
`;
